@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #f9f9f9 !important;
}
.main-color {
  color: #f9550e !important;
}
.bg-color {
  background-color: #f9550e !important;
}
.active {
  background-color: #333 !important;
}
.list-link {
  color: #333;
  background-color: yellow;
  text-decoration: none;
}
/* .transactions{
  background-color: #333;
  width: 100%;
  height: 800px;
} */


/* Loader */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-container{
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #f9550e; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

section {
  position: relative;
  padding: 100px 10px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto !important;
  overflow-x: hidden;
}
.login {
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 0;
  transform: translate(-50%, -50%);
}
.login-container {
  height: 550px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
}
.login-wallpaper {
  width: 100%;
  height: 100%;
  border-radius: 0 8px 8px 0;
  background: url("./assets/images/wallpaper.jpg");
  background-size: cover;
}

@media (max-width: 767.99px) {
  .login-wallpaper {
    display: none;
  }
}

.css-w4z10b-MuiStack-root > :not(style) + :not(style) {
  /* margin: 0; */
  margin-left: 0;
}

table th{
  text-align: left !important;
}
.table-container{
  position: relative;
  min-height: 300px;
}